import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Content, Campo } from './styles'
import axios from '../../../services/withAxios';
import PopupProps from '../../../interfaces/popupProps';
import ButtonsCrud from '../ButtonsCrud';
import { DesabilitarCampoCrud } from '../../../services/auth';
import Vendedor from '../../../interfaces/vendedor';
import Usuario from '../../../interfaces/usuario';
import PercentIcon from '@mui/icons-material/Percent';

const VendedorDialog: React.FC<PopupProps<Vendedor>> = ({ aberto, setAberto, entidade }) => {
    const [nome, setNome] = useState<string>('')
    const [comissao, setComissao] = useState<string>('')
    const [usuarioSelecionado, setUsuario] = useState<string>('')
    const [usuarios, setUsuarios] = useState<Usuario[]>()

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const handleFechar = () => {
        setAberto(false)
    }

    const handleSalvar = (e: React.SyntheticEvent) => {
        e.preventDefault()

        if (entidade) {
            var updateVendedor: Vendedor = {
                id: entidade.id,
                nome: nome.toUpperCase(),
                usuarioId: usuarioSelecionado ?? '',
                percentualComissao: Number(comissao) ?? 0
            }

            axios.put('/vendedor', updateVendedor)
                .then(resp => {
                    if (resp.status === 200)
                        setAberto(false)
                })
        } else {
            var novoVendedor: Vendedor = {
                nome: nome.toUpperCase(),
                usuarioId: usuarioSelecionado ?? '',
                percentualComissao: Number(comissao) ?? 0
            }

            axios.post('/vendedor', novoVendedor)
                .then(resp => {
                    if (resp.status === 200)
                        setAberto(false)
                })
        }
    }

    const handleDeletar = (e: any) => {
        if (entidade?.id) {
            axios.delete('/vendedor/' + entidade.id).then(resp => {
                if (resp.status === 200)
                    setAberto(false)
            })
        }
    }

    useEffect(() => {
        const buscarUsuarios = async () => {
            const dados = await axios.get('/usuario', { params: { avoidLoadingScreen: true } })
            setUsuarios(dados.data)
        }

        if (aberto) {
            buscarUsuarios()

            if (entidade) {
                setNome(entidade.nome)

                if (entidade.percentualComissao)
                    setComissao(String(entidade.percentualComissao))
                else
                    setComissao('')
            }
        }

    }, [aberto, entidade])

    useEffect(() => {
        if (usuarios !== undefined && usuarios.length > 0 && entidade !== null) {
            setUsuario(entidade.usuarioId)
        }
    }, [usuarios])

    useEffect(() => {
    }, [usuarioSelecionado])

    const comissaoKeyPress = (event: any) => {
        var keyCode = event.which ? event.which : event.keyCode;
        var isValid = (keyCode >= 48 && keyCode <= 57) || keyCode === 8 || keyCode === 44 || keyCode === 46; // Allow numbers (0-9), backspace (8), comma (44), and period (46)

        if (!isValid) {
            event.preventDefault();
        }
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={aberto}
            onClose={handleFechar}
        >
            <DialogTitle id="vendedor-dialog">
                {"Vendedor"}
            </DialogTitle>
            <DialogContent>
                <Content id="formVendedor" component="form" onSubmit={handleSalvar}>
                    <Campo
                        id="nome"
                        label="Nome"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        required
                        fullWidth
                        autoComplete="off"
                        autoFocus
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        disabled={DesabilitarCampoCrud()}
                    />
                    {usuarios && (
                        <FormControl fullWidth>
                        <InputLabel id="usuario-label">Usuário</InputLabel>
                        <Select
                            labelId="usuario-label"
                            id="usuario"
                            value={usuarioSelecionado}
                            label="Usuario"
                            required
                            disabled={DesabilitarCampoCrud()}
                            onChange={(e: any) => setUsuario(e.target.value as string)}
                        >
                            {
                                usuarios?.map((item: Usuario) => {
                                    return <MenuItem key={item.id} value={item.id}>{item.login}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    )}
                    <Campo
                        id="comissao"
                        label="Comissão"
                        type="number"
                        value={comissao}
                        onChange={(e) => setComissao(e.target.value)}
                        onKeyPress={comissaoKeyPress}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PercentIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Content>
            </DialogContent>
            <ButtonsCrud entidade={entidade} form={'formVendedor'} handleDeletar={handleDeletar} handleFechar={handleFechar} handleSalvar={handleSalvar} />
        </Dialog>
    )
}

export default VendedorDialog
