import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    Select,
    MenuItem,
    InputAdornment,
    IconButton,
    InputLabel,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Content, Campo, CampoSenha } from './styles'
import axios from '../../../services/withAxios';
import PopupProps from '../../../interfaces/popupProps';
import Usuario from '../../../interfaces/usuario';
import { NivelAcessoEnum } from '../../../utils/enum/nivelAcessoEnum';
import { FormControl } from '@mui/material';
import ButtonsCrud from '../ButtonsCrud';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import obterDescricaoNivelAcesso from '../../../utils/enum/nivelAcessoDescricao';

const UsuarioDialog: React.FC<PopupProps<Usuario>> = ({ aberto, setAberto, entidade }) => {
    const [login, setLogin] = useState<string>(entidade?.login || '')
    const [senha, setSenha] = useState<string>('')
    const [nivelAcesso, setNivelAcesso] = useState<NivelAcessoEnum | string>(entidade?.nivelAcesso || '')
    const [mostrarSenha, setMostrarSenha] = useState<boolean>(false)

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const handleFechar = () => {
        setAberto(false)
    }

    const handleSalvar = (e: React.SyntheticEvent) => {
        e.preventDefault()

        if (entidade) {
            var updateUsuario: Usuario = {
                id: entidade.id,
                login: login.toUpperCase(),
                senha,
                nivelAcesso: nivelAcesso || NivelAcessoEnum.usuario,
            }

            axios.put('/usuario', updateUsuario)
                .then(resp => {
                    if (resp.status === 200)
                        setAberto(false)
                })
        } else {
            var novoUsuario: Usuario = {
                login: login.toUpperCase(),
                senha,
                nivelAcesso: nivelAcesso || NivelAcessoEnum.usuario,
            }

            axios.post('/usuario', novoUsuario)
                .then(resp => {
                    if (resp.status === 200)
                        setAberto(false)
                })
        }
    }

    const handleDeletar = () => {
        if (entidade?.id) {
            axios.delete('/usuario/' + entidade.id).then(resp => {
                if (resp.status === 200)
                    setAberto(false)
            })
        }
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (entidade) {
            setLogin(entidade.login)
            setNivelAcesso(entidade.nivelAcesso.toString())
        }
    }, [aberto, entidade])

    return (
        <Dialog
            fullScreen={fullScreen}
            open={aberto}
            onClose={handleFechar}
        >
            <DialogTitle id="usuario-dialog">Usuario</DialogTitle>
            <DialogContent>
                <Content id="formUsuario" component="form" onSubmit={handleSalvar}>
                    <Campo
                        id="login"
                        label="Login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                        required
                        fullWidth
                        autoComplete='off'
                        autoFocus={true}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="senha">Senha</InputLabel>
                        <CampoSenha
                            id="senha"
                            label="Senha"
                            value={senha}
                            onChange={(e) => setSenha(e.target.value)}
                            type={mostrarSenha ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setMostrarSenha(!mostrarSenha)}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            fullWidth
                            autoComplete='off'
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <Select
                            id="nivelAcesso"
                            value={nivelAcesso}
                            onChange={(e) => setNivelAcesso(e.target.value as NivelAcessoEnum)}
                            required
                            fullWidth
                            displayEmpty
                            renderValue={(value) => value ? obterDescricaoNivelAcesso(value) : "Perfil"}
                        >
                            <MenuItem disabled>Nível de Acesso</MenuItem>
                            <MenuItem value={NivelAcessoEnum.admin}>Administrador</MenuItem>
                            <MenuItem value={NivelAcessoEnum.gerente}>Gerente</MenuItem>
                            <MenuItem value={NivelAcessoEnum.usuario}>Usuário</MenuItem>
                        </Select>
                    </FormControl>
                </Content>
            </DialogContent>
            <ButtonsCrud entidade={entidade} form={'formUsuario'} handleDeletar={handleDeletar} handleFechar={handleFechar} handleSalvar={handleSalvar} />
        </Dialog>
    )
}

export default UsuarioDialog
