import {
    Box,
    Dialog,
    Fab
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

const Content = styled('div')`
    
`;

const BotaoImportar = styled(Fab)`
    margin-top: 3%;
`

const DivisorStepper = styled(Divider)`
    margin-top: 10px;
    margin-bottom: 10px;
`

export { Content, BotaoImportar, DivisorStepper };