import {
    Box,
    FormControl,
    InputLabel,
    TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Content = styled(Box)`
    display: flex;
    padding: 1%;
    flex-wrap: wrap;
`;

const Campo = styled(TextField)`
    margin: 1vh 0 1vh 0;
`;

const FormControlEspacado = styled(FormControl)`
    margin: 1vh 0 1vh 0 !important;
`

const InputLabelBranco = styled(InputLabel)`
    background-color: #ffffff;
    padding-right: 4px;
    padding-left: 4px;
`

export { Content, Campo, FormControlEspacado, InputLabelBranco };