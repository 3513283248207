import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper'
import AssessmentIcon from '@mui/icons-material/Assessment'
import RequestPageIcon from '@mui/icons-material/RequestPage';
import {
  PaperProps, SwipeableDrawer, SwipeableDrawerProps, IconButton, List, ListItem, ListItemText, Button
} from '@mui/material';

const Div = styled('div')(({ theme }) => ({
  ...theme.typography,
}));

const Container = styled('div')<{ fullWidth: boolean }>`
    max-width: ${(props) => props.fullWidth ? 'unset' : '1024px'};
    height: 100%;
    margin: 0 auto;
    padding: 1vh 5vh 1vh 5vh;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
  `;

const Content = styled(Paper) <{ fullWidth: boolean }>`
    color: #D7DF23;
    background-color: #fff;
    margin-bottom: 1%;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 6%), 0 2px 5px 0 rgb(0 0 0 / 20%);
    border-radius: 2px;
    width: ${(props) => props.fullWidth ? '100%' : '1024px'};
    height: 100%;
    //transition: width 0.5s;
  `;

const Menu = styled(SwipeableDrawer) <SwipeableDrawerProps>`
    .MuiDrawer-paper {
      width: 26vh;
    }
  `

const Image = styled('img')`
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 5%
`;

const ListaOpcoesMenu = styled(List)`
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 0px;
    `

const TituloContainer = styled(Paper)`
    padding: 5px 20px 5px 20px;
`

const HeaderOptions = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-block: unset;
  margin: 5px 0px 5px 0px
`;

const FooterOptions = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-block: unset;
  margin: 5px 0px 5px 0px
`;

const OpcaoMenuTexto = styled(ListItemText)`
      span {
          font-family: "Montserrat";
          font-weight: 600;
          color: black;
      }
    `;

const BotaoMenu = styled(IconButton)`
    margin-bottom: auto;
    margin-right: 1vw;
  `

const ListItemLogout = styled(ListItem) <any>`
    border-top: 1px solid;
    border-top-width: thin;
    border-color: rgba(0, 0, 0, 0.2);
    margin-top: auto;

    svg, span {
        color: red !important;
        font-weight: bold;
}
`;

/// Button delete color is red and font weight is bold id #delete
const ButtonDelete = styled(Button) <any>`
    color: red !important;
    font-weight: bold;
`;

const IconRelatorio = styled(AssessmentIcon)`
  width: 30px;
  height: 30px;
`

const IconImportarNfe = styled(RequestPageIcon)`
  width: 30px;
  height: 30px;
`

const BotoesDeAcao = styled('div')`

`

export { 
  Div, 
  Container, 
  Content, 
  Menu, 
  ListaOpcoesMenu, 
  OpcaoMenuTexto, 
  BotaoMenu, 
  ListItemLogout, 
  TituloContainer, 
  HeaderOptions, 
  FooterOptions, 
  Image, 
  ButtonDelete, 
  IconRelatorio, 
  IconImportarNfe,
  BotoesDeAcao
};