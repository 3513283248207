import { TextField } from "@mui/material";
import { useState, useEffect, LegacyRef } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

export interface NumberFormatGFLProps extends NumberFormatProps {
  ref?: React.Ref<any>
}

const CurrencyInput = ({ value, onValueChange, disabled, color, ref, autoFocus }: NumberFormatGFLProps) => {
  const [internalValue, setInternalValue] = useState<string | number>(0);

  useEffect(() => {
    if (value === 0)
      setInternalValue(value);

    if (value && typeof value === "number")
      setInternalValue(value * 10000)
  }, [value])

  const handleChange = (v: any) => {
    setInternalValue(parseFloat(v.value) * 100);
    if (onValueChange) {
      onValueChange({ ...v, floatValue: v.floatValue / 100 }, v.value);
    }
  };

  const currencyFormatter = (formatted_value: any) => {
    if (!Number(formatted_value)) return "R$ 0,00";
    const br = { style: "currency", currency: "BRL" };
    return new Intl.NumberFormat("pt-BR", br).format(formatted_value / 100);
  };

  const keyDown = (e: any) => {
    if (e.code === "Backspace" && !internalValue) {
      e.preventDefault();
    }
    if (e.code === "Backspace" && internalValue < 1000) {
      e.preventDefault();
      setInternalValue(0);
    }
  };

  return (
    <NumberFormat
      ref={ref}
      autoFocus={autoFocus}
      value={Number(internalValue) / 100}
      format={currencyFormatter}
      onValueChange={handleChange}
      prefix={"R$ "}
      allowEmptyFormatting
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={2}
      customInput={TextField}
      onKeyDown={keyDown}
      disabled={disabled}
      color={color !== undefined ? 'warning' : undefined}
      focused={color === 'warning'}
    />
  );
};

export default CurrencyInput;