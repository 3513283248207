import React, { useState } from "react"
import { IMaskInput } from "react-imask"

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const MaskTelefone = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    const [mask, setMask] = useState<string>('(00) 00000-0000')
    return (
      <IMaskInput
        {...other}
        mask={mask}
        onBlur={e => {
          if (e.target.value.replace("_", "").length === 14) {
            setMask("(00) 0000-0000")
          }
        }}
        onFocus={e => {
          if (e.target.value.replace("_", "").length === 14) {
            setMask("(00) 00000-0000")
          }
        }}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    )
  },
)

export default MaskTelefone