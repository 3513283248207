import { useState } from 'react'
import './App.css'
import './index.css'
import Main from '../src/ui/pages/Main'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import Produtos from './ui/pages/Produtos'
import Login from './ui/pages/Login'
import Categorias from './ui/pages/Categoria'
import FrenteDeCaixa from './ui/pages/FrenteDeCaixa/index'
import Cliente from './ui/pages/Cliente'
import { ThemeProvider } from '@mui/material/styles'
import { GFLContext } from '../src/infra/GFLContext'
import Contexto from './interfaces/contexto'
import UsuarioInterface from './interfaces/usuario'
import { WithAxios } from '../src/services/withAxios'
import AlertComponent from './ui/components/AlertComponent'
import LoadingScreen from './ui/components/LoadingScreen'
import { TypeAlertEnum } from './utils/enum/typeAlertEnum'
import { getUser, isAuthenticated } from './services/auth'
import UsuarioPage from './ui/pages/Usuario'
import { ConfirmProvider } from 'material-ui-confirm'
import { NivelAcessoEnum } from './utils/enum/nivelAcessoEnum'
import Item from './interfaces/item'
import Vendas from './ui/pages/Vendas'
import Vendedores from './ui/pages/Vendedores'
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs'
import useGFLTheme from '../src/infra/theme'

datadogRum.init({
  applicationId: '8122017b-34f0-4dc5-afc1-47a4c684bda2',
  clientToken: 'pub2d42256302e0ae0084a76c5beb55c1e9',
  site: 'datadoghq.com',
  service: 'gfl-web',
  env: 'prod',
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
})

datadogLogs.init({
  clientToken: 'pub2d42256302e0ae0084a76c5beb55c1e9',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: "GFL Prod - Frontend",
})

datadogRum.startSessionReplayRecording();

function App() {
  const [loading, setLoading] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [typeAlert, setTypeAlert] = useState<TypeAlertEnum>(TypeAlertEnum.info)
  const [messageAlert, setMessageAlert] = useState<string>('')
  const [itens, setItens] = useState<Item[]>([])

  const usuario = getUser()
  const theme = useGFLTheme()

  const contexto = {
    loading,
    setLoading,
    openAlert,
    setOpenAlert,
    typeAlert,
    setTypeAlert,
    messageAlert,
    setMessageAlert,
    usuario: {} as UsuarioInterface,
    venda: {
      itens,
      setItens
    }
  } as Contexto

  const privateRoute = (page: JSX.Element) => {
    return isAuthenticated() ? page : <Navigate to={{ pathname: '/login' }} />
  }

  return (
    <BrowserRouter>
      <GFLContext.Provider value={contexto}>
        <ThemeProvider theme={theme}>
          <WithAxios>
            <ConfirmProvider>
              <Routes>
                <Route path="/" element={privateRoute(<Main />)}>
                  <Route path="produtos" element={privateRoute(<Produtos />)} />
                  <Route path="frentedecaixa" element={privateRoute(<FrenteDeCaixa />)} />
                  <Route path="categorias" element={privateRoute(<Categorias />)} />
                  <Route path="clientes" element={privateRoute(<Cliente />)} />
                  <Route path="vendas" element={privateRoute(<Vendas />)} />
                  <Route path="vendedores" element={usuario.role === NivelAcessoEnum.usuario ? <Navigate to={{ pathname: '/produtos' }} /> : privateRoute(<Vendedores />)} />
                  <Route path="usuarios" element={usuario.role === NivelAcessoEnum.usuario ? <Navigate to={{ pathname: '/produtos' }} /> : privateRoute(<UsuarioPage />)} />
                </Route>
                <Route path="login" element={
                  isAuthenticated() ? <Navigate to={{ pathname: '/' }} /> : <Login />
                } />
                <Route
                  path="*"
                  element={
                    <div style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                    </div>
                  }
                />
              </Routes>
              <LoadingScreen open={contexto.loading} />
              <AlertComponent typeAlert={contexto.typeAlert} messageAlert={contexto.messageAlert} open={contexto.openAlert} setOpen={contexto.setOpenAlert} />
            </ConfirmProvider>
          </WithAxios>
        </ThemeProvider>
      </GFLContext.Provider>
    </BrowserRouter >
  )
}

export default App
