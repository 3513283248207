import {
    Box,
    OutlinedInput,
    TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Content = styled(Box)`
    display: flex;
    padding: 1%;
    flex-wrap: wrap;
`;

const Campo = styled(TextField)`
    margin: 1vh 0 1vh 0;
`;

const CampoSenha = styled(OutlinedInput)`
    margin-bottom: 1%;
`

export { Content, Campo, CampoSenha };