import { useState } from "react"
import { DialogActions, Button, Dialog, DialogTitle } from "@mui/material"
import { ButtonSalvar, ButtonDeletar } from "./style"
import { useConfirm } from 'material-ui-confirm';
import { DesabilitarCampoCrud, getUser } from "../../../services/auth";

const ButtonsCrud: React.FC<({ entidade: any, handleDeletar: React.Dispatch<React.SetStateAction<any>>, handleFechar: React.Dispatch<React.SetStateAction<any>>, handleSalvar: React.Dispatch<React.SetStateAction<any>>, form: any })> = ({ entidade, handleDeletar, handleFechar, handleSalvar, form }) => {
  const [open, setOpen] = useState(false)
  const confirm = useConfirm()
  const user = getUser();

  const handleClickDeletar = () => {
    confirm({ description: 'Excluir permanentemente?', title: 'Tem certeza?', confirmationButtonProps: { autoFocus: true } })
      .then(handleDeletar)
      .catch(() => { });
  };

  return <DialogActions>
    <ButtonDeletar disabled={entidade?.id === undefined || DesabilitarCampoCrud()} onClick={handleClickDeletar} autoFocus>
      Deletar
    </ButtonDeletar>
    <Button onClick={handleFechar} autoFocus>
      Cancelar
    </Button>
    <ButtonSalvar autoFocus type="submit" form={form} disabled={DesabilitarCampoCrud()}>
      Salvar
    </ButtonSalvar>
  </DialogActions>
}
export default ButtonsCrud