import React, { useState, useEffect, useContext } from 'react'
import { IconButton, TextField, Divider, debounce } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { GridColDef, GridRowParams, GridValueGetterParams, ptBR } from '@mui/x-data-grid'
import { Header, Content, Image, StyledDataGrid } from './style'
import axios from '../../../services/withAxios';
import VendedoresIcon from '../../../assets/vendedores.png'
import Vendedor from '../../../interfaces/vendedor'
import VendedorDialog from '../../components/VendedorDialog'

const Vendedores: React.FC = () => {
  const [pesquisa, setPesquisa] = useState<string>('')
  const [dialogAberto, setDialogAberto] = useState<boolean>(false)
  const [vendedorSelecionado, setVendedorSelecionado] = useState<Vendedor | null>(null)
  const [vendedores, setVendedores] = useState<Vendedor[]>([])
  const [pesquisarTrigger, setPesquisarTrigger] = useState<boolean>(false);

  const pesquisar = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setPesquisa(event.target.value);
  }, 500)

  useEffect(() => {
    if (dialogAberto)
      return

    const buscarDados = async () => {
      if (pesquisa === '')
        await axios('/vendedor')
          .then(resp => {
            setVendedores(resp.data === '' ? [] : resp.data)
          })
      else
        await axios(`/vendedor/search/${pesquisa}`)
          .then(resp => {
            setVendedores(resp.data === '' ? [] : resp.data)
          })
    }

    buscarDados()
  }, [pesquisa, pesquisarTrigger, dialogAberto])

  const colunas: GridColDef[] = [
    {
      field: 'usuarioLogin',
      headerName: 'Usuario',
      width: 300,
      valueGetter: (getter: GridValueGetterParams) => {
        return getter.value === null ? '-' : getter.value
      }
    },
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 1
    },
    {
      field: 'percentualComissao',
      headerName: 'Comissão (%)',
      flex: 1,
      valueGetter: (getter: GridValueGetterParams) => {
        return getter.value === null ? '-' : getter.value.toFixed(2)
      }
    }
  ]

  const handleAdicionar = () => {
    setVendedorSelecionado(null)
    setDialogAberto(!dialogAberto)
  }

  const handleClickLinha = (params: GridRowParams) => {
    setVendedorSelecionado(params.row as Vendedor)
    setDialogAberto(true)
  }

  return (
    <>
      <Image src={VendedoresIcon} />
      <Header>
        <TextField
          id="pesquisa"
          label="Pesquisar"
          onChange={pesquisar}
          type="search"
          fullWidth
          autoFocus
          autoComplete='off'
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <IconButton
          color="primary"
          onClick={_ => setPesquisarTrigger(!pesquisarTrigger)}
        >
          <SearchIcon />
        </IconButton>
        <Divider orientation="vertical" variant="middle" flexItem />
        <IconButton
          onClick={handleAdicionar}
          color="primary"
        >
          <AddCircleIcon />
        </IconButton>
      </Header>
      <Content>
        <StyledDataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          columns={colunas}
          rows={vendedores}
          disableColumnMenu={true}
          hideFooterSelectedRowCount={true}
          onRowClick={handleClickLinha}
          sx={{
            textTransform: 'uppercase'
          }}
        />
      </Content>
      {dialogAberto ? <VendedorDialog aberto={dialogAberto} setAberto={setDialogAberto} entidade={vendedorSelecionado} /> : <></>}
    </>
  )
}

export default Vendedores