import { Button, TextField, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

const Title = styled(Typography)`
  margin: 1%;
  font-size: 4rem !important;
  margin: 1%;
`;

const Content = styled('div')`
  color: #000000;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1%;
`;

const Campo = styled(TextField)`
    margin: 1vh 0 1vh 0;
`;

const StyledDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }

  .MuiDataGrid-row:first-of-type:not(:hover) {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const OpcoesGrid = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const BotaoFinalizarVenda = styled(Button)`
  margin-left: 20vw;
  max-height: 50px;
  
  &:hover {
    background-color: green;
  }
`

const DivisorFinalizacao = styled(Divider)`
  width: 100%;
  margin: 5vh 0vh 2vh 0vh;

  &:before {
    top: unset;
  }

  &:after {
    top: unset;
  }
`

export { Title, Content, Campo, StyledDataGrid, BotaoFinalizarVenda, OpcoesGrid, DivisorFinalizacao as DivisorResumo };