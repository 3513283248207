import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

const Title = styled(Typography)`
  margin: 1%;
  font-size: 4rem !important;
  margin: 1%;
`;

const Header = styled('div')`
    display: flex;
    justify-content: center;
    margin: 1%;
  `;

const Content = styled('div')`
  display: flex;
  justify-content: center;
  margin: 1%;
  height: 400px;
`;

const Image = styled('img')`
  padding: 1%;
`

const StyledDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }
`;

export { Title, Header, Content, Image, StyledDataGrid };