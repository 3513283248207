import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Content, Campo } from './styles'
import axios from '../../../services/withAxios';
import Cliente from '../../../interfaces/cliente'
import MaskCpf from '../../../utils/MaskCpf/index'
import MaskTelefone from '../../../utils/MaskTelefone'
import PopupProps from '../../../interfaces/popupProps';
import ButtonsCrud from '../ButtonsCrud';
import { DesabilitarCampoCrud } from '../../../services/auth';

const ClienteDialog: React.FC<PopupProps<Cliente>> = ({ aberto, setAberto, entidade }) => {
        const [nome, setNome] = useState<string>('')
        const [telefone, setTelefone] = useState<string>('')
        const [cpf, setCpf] = useState<string>('')
        const [observacao, setObservacao] = useState<string>('')

        const theme = useTheme()
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

        const handleFechar = () => {
            setAberto(false)
        }

        const handleSalvar = (e: React.SyntheticEvent) => {
            e.preventDefault()

            if (entidade) {
                var updateCliente: Cliente = {
                    id: entidade.id,
                    nome: nome.toUpperCase(),
                    telefone,
                    cpf,
                    observacao: observacao.toUpperCase()
                }

                axios.put('/cliente', updateCliente)
                    .then(resp => {
                        if (resp.status === 200)
                            setAberto(false)
                    })
            } else {
                var novoCliente: Cliente = {
                    nome: nome.toUpperCase(),
                    telefone,
                    cpf,
                    observacao: observacao.toUpperCase()
                }

                axios.post('/cliente', novoCliente)
                    .then(resp => {
                        if (resp.status === 200)
                            setAberto(false)
                    })
            }
        }

        const handleDeletar = () => {
            if (entidade?.id) {
                axios.delete('/cliente/' + entidade.id).then(resp => {
                    if (resp.status === 200)
                        setAberto(false)
                })
            }
        }

        useEffect(() => {
            if (entidade) {
                setNome(entidade.nome)
                setTelefone(entidade.telefone)
                setCpf(entidade.cpf)
                setObservacao(entidade.observacao)
            }
        }, [aberto, entidade])

        return (
            <Dialog
                fullScreen={fullScreen}
                open={aberto}
                onClose={handleFechar}
            >
                <DialogTitle id="cliente-dialog">Cliente</DialogTitle>
                <DialogContent>
                    <Content id="formCliente" component="form" onSubmit={handleSalvar}>
                        <Campo
                            id="nome"
                            label="Nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            required
                            fullWidth
                            autoComplete='off'
                            autoFocus
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            disabled={DesabilitarCampoCrud()}
                        />
                        <Campo
                            id="telefone"
                            label="Telefone"
                            value={telefone}
                            placeholder="(00) 00000-0000"
                            onChange={(e) => setTelefone(e.target.value)}
                            required
                            fullWidth
                            type="tel"
                            InputProps={{
                                inputComponent: MaskTelefone as any,
                            }}
                            autoComplete='off'
                            InputLabelProps={{ shrink: true }}
                            disabled={DesabilitarCampoCrud()}
                        />
                        <Campo
                            id="cpf"
                            label="CPF"
                            value={cpf}
                            placeholder="000.000.000-00"
                            onChange={(e) => setCpf(e.target.value)}
                            required
                            fullWidth
                            InputProps={{
                                inputComponent: MaskCpf as any
                            }}
                            autoComplete='off'
                            InputLabelProps={{ shrink: true }}
                            disabled={DesabilitarCampoCrud()}
                        />
                        <Campo
                            id="observacao"
                            label="Observação"
                            value={observacao}
                            onChange={(e) => setObservacao(e.target.value)}
                            fullWidth
                            autoComplete='off'
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            disabled={DesabilitarCampoCrud()}
                        />
                    </Content>
                </DialogContent>
                <ButtonsCrud entidade={entidade} form={'formCliente'} handleDeletar={handleDeletar} handleFechar={handleFechar} handleSalvar={handleSalvar} />
            </Dialog>
        )
    }

export default ClienteDialog
