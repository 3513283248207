import { Backdrop, CircularProgress } from "@mui/material";
import useGFLTheme from "../../../infra/theme";

export default function LoadingScreen({ open }: { open: boolean }) {
  const theme = useGFLTheme()
  const backdropStyle = {
    color: "#fff",
    zIndex: (theme: any) =>
      Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
  } as any;

  return (
    <div>
      <Backdrop open={open} sx={backdropStyle}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}