import { FormControl, InputLabel, Select, SelectProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

const Title = styled(Typography)`
  margin: 1%;
  font-size: 4rem !important;
  margin: 1%;
`;

const Content = styled('div')`
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 1%;
  height: 400px;
`;

const Campo = styled(Select)`
    margin: 1vh 0 1vh 0;
`;

const StyledDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }
`;

const FormControlEspacado = styled(FormControl)`
    margin: 1vh 0 1vh 0 !important;
`

const InputLabelBranco = styled(InputLabel)`
    background-color: #ffffff;
    padding-right: 4px;
    padding-left: 4px;
`

export { Title, Content, Campo, StyledDataGrid, FormControlEspacado, InputLabelBranco };