import {
    Box,
    Button,
    Divider,
    FormControl,
    Select,
    Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { DataGrid } from '@mui/x-data-grid'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { NONAME } from 'dns';

export const Content = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 4px;
    width: 1024px;
    height: 100%;
    margin: 0 auto;
    padding: 1%;
    background-color: white;
`;

export const Header = styled('div')`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

export const Filters = styled('div')`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
`

export const Row = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 15px;
`

export const ButtonsSection = styled('div')`
    display: inline-flex;
    justify-content: center;
    width: 100%;
    gap: 15px;
`

export const Campo = styled(Select)`
    margin: 1vh 0 1vh 0;
`;

export const ActionButton = styled(Button)`
    width: 18%;
`

export const ReportInfo = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    justify-content: flex-start;
    align-items: center;
`

export const TitleIcon = styled(AssessmentIcon)`
    width: 50px;
    height: 50px;
`

export const DivisorGrid = styled(Divider)`
  width: 100%;
  margin: 0.5vh 0vh 0.8vh 0vh;

  &:before {
    top: unset;
  }

  &:after {
    top: unset;
  }
`

export const StyledDataGrid = styled(DataGrid)({
  width: "100%",
  height: "50vh",
  '& .report-grid-theme--Branco': {
    backgroundColor: "white !important"
  },
  '& .report-grid-theme--Cinza': {
    backgroundColor: "#F0F0F0 !important"
  }
})

// export const StyledDataGrid = styled(DataGrid)`
// width: 100%;
// height: 50vh;
// &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
// &.MuiDataGrid-root .MuiDataGrid-cell:focus {
//   outline: none;
// }

// '& .report-grid-theme--Branco': {
//   backgroundColor: #white
// },
// '& .report-grid-theme--Cinza': {
//   backgroundColor: #grey
// },
// `;

export const NoDataInfo = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const VendedorFormControl = styled(FormControl)`

`

export const VendedorCampo = styled(Select)`
    margin: 1vh 0 1vh 0;
    width: 200px;
`;