import {
    Paper,
    Divider,
    Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Content = styled(Box)`
    display: flex;
    padding: 0vh 0vh 0.2vh 0vh;
    gap: 12px;
    width: 100%;
    justify-content: center;
`;

export const Card = styled(Paper)`
    display: flex;
    width: 10vw;
    padding: 1%;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 1vh 0vh;
    justify-content: space-evenly;
`;

export const Divisor = styled(Divider)`
  width: 100%;
  margin: 0.5vh 0vh 0.2vh 0vh;

  &:before {
    top: unset;
  }

  &:after {
    top: unset;
  }
`