import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  TextField
} from "@mui/material";
import { GridColDef, ptBR } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState, useMemo } from "react"
import {
  Campo,
  Content,
  FormControlEspacado,
  InputLabelBranco,
  StyledDataGrid
} from "./styles"
import Cliente from './../../../interfaces/cliente';
import axios from '../../../services/withAxios';
import { AxiosResponse } from "axios";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toReal } from "../../../utils/MaskMonetario";
import { GFLContext } from "../../../infra/GFLContext";
import { TypeAlertEnum } from "../../../utils/enum/typeAlertEnum";
import { FormaPagamentoEnum } from "../../../utils/enum/formaPagamentoEnum";
import obterDescricaoFormaPagamento from "../../../utils/enum/formaPagamentoDescricao";
import { DownloadVenda } from "../../../services/DownloadVenda";
import brLocale from 'date-fns/locale/pt-BR';
import { EsconderPorNivelAcesso, getUser } from "../../../services/auth";
import { NivelAcessoEnum } from "../../../utils/enum/nivelAcessoEnum";
import CurrencyInput from "../CurrencyInput";
import { NumberFormatValues } from "react-number-format";
import toIsoString from "../../../utils/FormatDate/formatDateToIso";
import { datadogLogs } from '@datadog/browser-logs'
import Vendedor from "../../../interfaces/vendedor";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const colunas: GridColDef[] = [
  {
    field: 'produtoNome',
    headerName: 'Produto',
    width: 250
  },
  {
    field: 'quantidade',
    headerName: 'Quantidade',
    flex: 1
  },
  {
    field: 'valor',
    headerName: 'Valor Total',
    flex: 1,
    valueGetter: (params: any) => {
      const total = params.row.produtoValor * params.row.quantidade;

      return toReal(total);
    }
  }
]

const FinalizarCompraDialog: React.FC<(
  {
    aberto: boolean, 
    setAberto: React.Dispatch<React.SetStateAction<boolean>>,
    clientes: Cliente[],
    vendedores: Vendedor[]
  })> =
  ({ aberto, setAberto, clientes, vendedores }) => {
    const [dataVenda, setDataVenda] = useState<Date | null>(new Date())
    const [desconto, setDesconto] = useState<number>()
    const [formaDePagamento, setFormaDePagamento] = useState<FormaPagamentoEnum | string>('')
    const [valorTotal, setValorTotal] = useState<number>()
    const [clienteSelecionado, setClienteSelecionado] = useState<string>('')
    const [vendedorSelecionado, setVendedorSelecionado] = useState<string>('')
    const [desabilitarBotaoConcluir, setDesabilitarBotaoConcluir] = useState<boolean>(false)

    const [descontoHighlight, setDescontoHighlight] = useState<boolean>(false)
    const [valorTotalHighlight, setValorTotalHighlight] = useState<boolean>(false)

    const contexto = useContext(GFLContext)
    const venda = contexto.venda

    useEffect(() => {
      if (aberto) {
        setValorTotal(totalVenda)
      }

    }, [aberto])

    useEffect(() => {
      if (!valorTotal)
        return

      setDesconto(totalVenda - valorTotal)
      ativarHighlightDesconto()
    }, [valorTotal])

    useEffect(() => {
      if (vendedores !== undefined && vendedores.length > 0)
        selecionarVendedorPeloUsuarioLogado()
    }, [vendedores])

    useEffect(() => {

      if (descontoHighlight)
        setTimeout(() => {
          setDescontoHighlight(false)
        }, 1000)

      if (valorTotalHighlight)
        setTimeout(() => {
          setValorTotalHighlight(false)
        }, 1000)

    }, [descontoHighlight, valorTotalHighlight])

    const handleClose = () => {
      if (formaDePagamento === '') {
        mensagemValidacao("É necessário informar a forma de pagamento para concluir a venda!")
        return
      }

      setDesabilitarBotaoConcluir(true)
      const novaVenda = {
        itens: venda.itens,
        clienteId: clienteSelecionado === '' ? null : clienteSelecionado,
        valorDesconto: desconto,
        formaPagamento: formaDePagamento,
        data: toIsoString(dataVenda),
        vendedorId: (vendedorSelecionado === '' || vendedorSelecionado === '0') ? null : vendedorSelecionado
      };

      axios.post('/venda', novaVenda).then((e: AxiosResponse) => {
        datadogLogs.logger.info('Venda efetuada', { venda: { ...novaVenda }, axiosResponse: e })

        if (e.status === 200) {
          setAberto(false)
          venda.setItens([])
        }

      }).finally(() => {
        setDesabilitarBotaoConcluir(false)
      })
    }

    const validarParaBaixarResumo = () => {
      return (formaDePagamento !== '' && clienteSelecionado !== "")
    }

    const ativarHighlightDesconto = () => {
      setDescontoHighlight(true)
    }

    const ativarHighlightValorTotal = () => {
      setValorTotalHighlight(true)
    }

    const mensagemValidacao = (mensagem: string, type?: TypeAlertEnum) => {
      contexto.setMessageAlert(mensagem)
      contexto.setTypeAlert(type ? type : TypeAlertEnum.warning)
      contexto.setOpenAlert(true)
    }

    const handleDownloadVenda = () => {
      if (!validarParaBaixarResumo()) {
        mensagemValidacao("É necessário preencher a forma de pagamento e o cliente para imprimir o resumo de venda.")
        return
      }

      const cliente = clientes.find(c => c.id === clienteSelecionado) ?? { nome: '', endereco: '', telefone: '', cpf: '', observacao: '' }
      const formaDePagamentoPdf = formaDePagamento === '' ? FormaPagamentoEnum.credito : formaDePagamento

      try {
        DownloadVenda({ vendaItens: venda.itens, cliente, formaPagamento: formaDePagamentoPdf, valorTotal: totalVenda, dataVenda: dataVenda ?? new Date(), desconto: desconto ? desconto : 0 })
      } catch (e: any) {
        mensagemValidacao(`Houve um problema ao imprimir a venda. Log:${e.message}`, TypeAlertEnum.error);
      }
    }

    const calcularTotal = () => {
      const total = venda.itens.reduce((total, item) => {
        return total + (item.produtoValor * item.quantidade)
      }, 0)

      return total
    }

    const totalVenda = useMemo(() => calcularTotal(), [venda.itens])

    const selecionarVendedorPeloUsuarioLogado = () => {
      const { unique_name } = getUser()
      const vendedorLogado = vendedores.find(vendedor => vendedor.usuarioLogin === unique_name)

      if (vendedorLogado !== undefined)
        setVendedorSelecionado(vendedorLogado.id ?? '')
    }

    const handleValorTotalChange = (v: NumberFormatValues) => {
      if (!v.floatValue) {
        setValorTotal(0)

        return
      }

      if (v.floatValue > totalVenda) {
        mensagemValidacao(`O valor total da venda não pode ser maior que o valor original de ${toReal(totalVenda)}`)
        ativarHighlightValorTotal()
        setValorTotal(totalVenda)
      }
      else
        setValorTotal(v.floatValue)
    }

    const handleDescontoChange = (v: NumberFormatValues) => {
      if (v.floatValue === undefined || v.floatValue === null) {
        setDesconto(0)

        return
      }

      if (v.floatValue > totalVenda) {
        mensagemValidacao(`O desconto não pode ser maior que o valor da venda de ${toReal(totalVenda)}`)
        setDesconto(0)
        setValorTotal(totalVenda)
      } else {
        setDesconto(v.floatValue)
        setValorTotal(totalVenda - v.floatValue)
      }

      ativarHighlightValorTotal()
    }

    return (
      <>
        <Dialog
          open={aberto}
          onClose={() => setAberto(false)}
          fullScreen={useMediaQuery("(max-width: 1024px)")}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Finalizar Venda</DialogTitle>
          <DialogContent>
            <Content>
              {venda.itens.length > 0 ?
                <StyledDataGrid
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  columns={colunas}
                  rows={venda.itens}
                  disableColumnMenu={true}
                  hideFooter={true}
                  getRowId={(row: any) => Math.random()}
                /> : null}
            </Content>
            <EsconderPorNivelAcesso nivelAcesso={[NivelAcessoEnum.admin, NivelAcessoEnum.gerente]}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
                  <DatePicker
                    label="Data da venda"
                    value={dataVenda}
                    inputFormat="dd/MM/yyyy"
                    onChange={(novaData) => {
                      setDataVenda(novaData);
                    }}
                    renderInput={(params) => <TextField style={{ margin: "1vh 0 1vh 0" }}  {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </EsconderPorNivelAcesso>
            <FormControl fullWidth>
              <FormControlEspacado focused variant='outlined' fullWidth>
                <InputLabelBranco>Desconto</InputLabelBranco>
                <CurrencyInput
                  onValueChange={handleDescontoChange}
                  value={desconto}
                  color={valorTotalHighlight ? 'warning' : undefined}
                  required
                  autoComplete='off'
                  maxLength={20}
                />
              </FormControlEspacado>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="formaDePagamentoId">Forma de Pagamento</InputLabel>
              <Campo
                labelId="formaDePagamentoId"
                id="formaDePagamentoId"
                value={formaDePagamento}
                required
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={(e) => setFormaDePagamento(e.target.value as FormaPagamentoEnum)}
                renderValue={(value) => value !== null ? obterDescricaoFormaPagamento(value as FormaPagamentoEnum) : "Selecionar..."}
              >
                <MenuItem disabled>Forma de Pagamento</MenuItem>
                <MenuItem value={FormaPagamentoEnum.debito}>Débito</MenuItem>
                <MenuItem value={FormaPagamentoEnum.credito}>Crédito</MenuItem>
                <MenuItem value={FormaPagamentoEnum.pix}>Pix</MenuItem>
                <MenuItem value={FormaPagamentoEnum.dinheiro}>Dinheiro</MenuItem>
              </Campo>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="clienteId">Cliente</InputLabel>
              <Campo
                labelId="clienteId"
                id="clienteId"
                value={clienteSelecionado}
                onChange={(e) => setClienteSelecionado(e.target.value as string)}
              >
                {
                  clientes.map(cliente => (
                    <MenuItem key={cliente.id} value={cliente.id}>{cliente.nome.toUpperCase()}</MenuItem>
                  ))
                }
              </Campo>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="vendedorId">Vendedor</InputLabel>
              <Campo
                labelId="vendedorId"
                id="vendedorId"
                value={vendedorSelecionado}
                onChange={(e) => setVendedorSelecionado(e.target.value as string)}
              >
                <MenuItem key={'0'} value={'0'}>NENHUM</MenuItem>
                {
                  vendedores.map(vendedor => (
                    <MenuItem key={vendedor.id} value={vendedor.id}>{vendedor.nome.toUpperCase()}</MenuItem>
                  ))
                }
              </Campo>
            </FormControl>
            <FormControl fullWidth>
              <FormControlEspacado focused variant='outlined' fullWidth>
                <InputLabelBranco>Valor Total</InputLabelBranco>
                <CurrencyInput
                  onValueChange={handleValorTotalChange}
                  value={valorTotal}
                  required
                  color={valorTotalHighlight ? 'warning' : undefined}
                  autoComplete='off'
                  maxLength={20}
                />
              </FormControlEspacado>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setAberto(false)}>Fechar</Button>
            <Button color="secondary" onClick={handleDownloadVenda}>Baixar Resumo da Venda</Button>
            <Button onClick={handleClose} disabled={desabilitarBotaoConcluir}>Concluir</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

export default FinalizarCompraDialog