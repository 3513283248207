import React, { useState, useEffect } from 'react'
import { IconButton, TextField, Divider, debounce } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { GridColDef, GridRowParams, GridValueGetterParams, ptBR } from '@mui/x-data-grid'
import { Header, Content, Image, StyledDataGrid } from './styles'
import Usuario from '../../../interfaces/usuario'
import UsuarioDialog from '../../components/UsuarioDialog'
import axios from '../../../services/withAxios';
import User from '../../../assets/user.png'
import { NivelAcessoEnum } from '../../../utils/enum/nivelAcessoEnum'
import obterDescricaoNivelAcesso from '../../../utils/enum/nivelAcessoDescricao'

const colunas: GridColDef[] = [
  {
    field: 'login',
    headerName: 'Login',
    flex: 1
  },
  {
    field: 'nivelAcesso',
    headerName: 'Nivel Acesso',
    flex: 1,
    valueGetter: (nivelAcesso: GridValueGetterParams<any, any>) => {
      return obterDescricaoNivelAcesso(nivelAcesso.value.toString())
    }
  }
]

const Usuarios: React.FC = () => {
  const [pesquisa, setPesquisa] = useState<string>('')
  const [dialogAberto, setDialogAberto] = useState<boolean>(false)
  const [usuarios, setUsuarios] = useState<Usuario[]>([])
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<Usuario | null>(null)
  const [pesquisarTrigger, setPesquisarTrigger] = useState<boolean>(false);

  const pesquisar = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setPesquisa(event.target.value);
  }, 500)

  useEffect(() => {
    if (dialogAberto)
      return

    const buscarDados = async () => {
      if (pesquisa === '')
        await axios('/usuario')
          .then(resp => {
            setUsuarios(resp.data === '' ? [] : resp.data)
          })
      else
        await axios(`/usuario/search/${pesquisa}`)
          .then(resp => {
            setUsuarios(resp.data === '' ? [] : resp.data)
          })
    }

    buscarDados()
  }, [pesquisa, pesquisarTrigger, dialogAberto])

  const handleAdicionar = () => {
    setUsuarioSelecionado(null)
    setDialogAberto(!dialogAberto)
  }

  const handleClickLinha = (params: GridRowParams) => {
    setUsuarioSelecionado(params.row as Usuario)
    setDialogAberto(true)
  }

  return (
    <>
      <Image src={User} />
      <Header>
        <TextField
          id="pesquisa"
          label="Pesquisar"
          onChange={pesquisar}
          type="search"
          fullWidth
          autoFocus
          autoComplete='off'
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <IconButton
          color="primary"
          onClick={_ => setPesquisarTrigger(!pesquisarTrigger)}
        >
          <SearchIcon />
        </IconButton>
        <Divider orientation="vertical" variant="middle" flexItem />
        <IconButton
          onClick={handleAdicionar}
          color="primary"
        >
          <AddCircleIcon />
        </IconButton>
      </Header>
      <Content>
        <StyledDataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          columns={colunas}
          rows={usuarios}
          disableColumnMenu={true}
          hideFooterSelectedRowCount={true}
          onRowClick={handleClickLinha}
          sx={{
            textTransform: 'uppercase'
          }}
        />
      </Content>
      {dialogAberto ? <UsuarioDialog aberto={dialogAberto} setAberto={setDialogAberto} entidade={usuarioSelecionado} /> : <></>}
    </>
  )
}

export default Usuarios