import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ButtonDeletar = styled(Button) <any>`
  color: ${props => props.disabled ? 'unset' : 'red !important'};
`;

const ButtonSalvar = styled(Button) <any>`
  color: ${props => props.disabled ? 'unset' : 'green !important'};
`;

export { ButtonDeletar, ButtonSalvar };