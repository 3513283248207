import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Content, Campo } from './styles'
import axios from '../../../services/withAxios';
import Categoria from '../../../interfaces/categoria'
import PopupProps from '../../../interfaces/popupProps';
import ButtonsCrud from '../ButtonsCrud';
import { DesabilitarCampoCrud } from '../../../services/auth';

const CategoriaDialog: React.FC<PopupProps<Categoria>> = ({ aberto, setAberto, entidade }) => {
        const [nome, setNome] = useState<string>('')

        const theme = useTheme()
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

        const handleFechar = () => {
            setAberto(false)
        }

        const handleSalvar = (e: React.SyntheticEvent) => {
            e.preventDefault()
            if (entidade) {
                var updateCategoria: Categoria = {
                    id: entidade.id,
                    nome: nome.toUpperCase(),
                }

                axios.put('/categoria', updateCategoria)
                    .then(resp => {
                        if (resp.status === 200)
                            setAberto(false)
                    })
            } else {
                var novaCategoria: Categoria = {
                    nome: nome.toUpperCase(),
                }

                axios.post('/categoria', novaCategoria)
                    .then(resp => {
                        if (resp.status === 200)
                            setAberto(false)
                    })
            }
        }

        const handleDeletar = (e: any) => {
            if (entidade?.id) {
                axios.delete('/categoria/' + entidade.id).then(resp => {
                    if (resp.status === 200)
                        setAberto(false)
                })
            }
        }

        useEffect(() => {
            if (entidade) {
                setNome(entidade.nome)
            }
        }, [aberto, entidade])

        return (
            <Dialog
                fullScreen={fullScreen}
                open={aberto}
                onClose={handleFechar}
            >
                <DialogTitle id="categoria-dialog">
                    {"Categoria"}
                </DialogTitle>
                <DialogContent>
                    <Content id="formCategoria" component="form" onSubmit={handleSalvar}>
                        <Campo
                            id="nome"
                            label="Nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            required
                            fullWidth
                            autoComplete="off"
                            autoFocus
                            inputProps={{ style: { textTransform: "uppercase" } }}  
                            disabled={DesabilitarCampoCrud()}             
                        />
                    </Content>
                </DialogContent>
                <ButtonsCrud entidade={entidade} form={'formCategoria'} handleDeletar={handleDeletar} handleFechar={handleFechar} handleSalvar={handleSalvar} />
            </Dialog>
        )
    }

export default CategoriaDialog
