import { createContext, useContext } from 'react';
import { createTheme, ThemeOptions } from '@mui/material/styles'
import { GFLContext } from './GFLContext'
import { ptBR } from '@mui/x-data-grid'
import { ptBR as corePtBr } from '@mui/material/locale'

function useGFLTheme() {
  const { loading } = useContext(GFLContext);

  const theme = createTheme({
    overrides: {
      MuiButton: {
        root: {
          pointerEvents: 'none',
          opacity: 0,
        },
      },
    },
    MuiButton: {
      root: {
        pointerEvents: 'none',
        opacity: 0,
      },
    },
    palette: {
      type: 'light',
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#f0000c"
      },
    },
    typography: {
      fontFamily: "Montserrat"
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
          {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    ptBR,
    corePtBr
  } as ThemeOptions)

  return theme;
}

export default useGFLTheme;