import { TypeAlertEnum } from './../utils/enum/typeAlertEnum';
import { createContext} from 'react';
import Contexto from '../interfaces/contexto';

const defaultState = {
    loading: false,
    messageAlert: '',
    typeAlert: TypeAlertEnum.info,
    openAlert: false,
    usuario: {}
  } as Contexto;

export const GFLContext = createContext<Contexto>(defaultState)