import { NivelAcessoEnum } from './nivelAcessoEnum';

const obterDescricaoNivelAcesso = (nivelAcesso: NivelAcessoEnum | string) => {

    switch (nivelAcesso) {
        case NivelAcessoEnum.admin:
            return "Administrador"
        case NivelAcessoEnum.gerente:
            return "Gerente"
        case NivelAcessoEnum.usuario:
            return "Usuário"
        default:
            return "Perfil"
    }
}

export default obterDescricaoNivelAcesso