import { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { TypeAlertEnum } from '../../../utils/enum/typeAlertEnum';
import { Snackbar } from '@mui/material';

const AlertComponent: React.FC<{
  messageAlert: string | null,
  typeAlert: TypeAlertEnum
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> =
  ({ messageAlert, typeAlert, open, setOpen }) => {

    const definirAutoHideDuration = () => {
      switch (typeAlert) {
        case TypeAlertEnum.success:
          return 2000
        case TypeAlertEnum.error:
          return 5000
        case TypeAlertEnum.info:
          return 7000
        case TypeAlertEnum.warning:
          return 7000
      }
    }

    return (
      <Snackbar autoHideDuration={definirAutoHideDuration()} onClose={_ => setOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open}>
        <Alert
          variant='filled'
          severity={typeAlert}
        >
          {messageAlert}
        </Alert>
      </Snackbar>
    )
  }

export default AlertComponent