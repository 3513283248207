import { FormaPagamentoEnum } from './formaPagamentoEnum';

const obterDescricaoFormaPagamento = (formaPagamento: FormaPagamentoEnum | string) => {

    switch (formaPagamento) {
        case FormaPagamentoEnum.debito:
            return "Débito"
        case FormaPagamentoEnum.credito:
            return "Crédito"
        case FormaPagamentoEnum.pix:
            return "Pix"
        case FormaPagamentoEnum.dinheiro:
            return "Dinheiro"
        default:
            return ''
    }
}

export default obterDescricaoFormaPagamento