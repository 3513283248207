import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Campo = styled(TextField)`
    margin: 1%;
`

export const MainContainer = styled('div')`
    display: flex;
    background-color: #797D81 !important;
    height: 100%;

    input {
        font-weight: 600;
    }
`;

export const Logo = styled('div')`
    width: 100%;
    display: flex;
    justify-content: center;

    img {
        width: auto;
        height: 15vh;
    }
`;

export const LoginContainer = styled('form')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    background-color: #f5f5f5;
    flex: 1;
`;

export const IntroContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
`;

export const LoginTitle = styled('span')`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
`;

export const LoginField = styled(TextField)`
    width: 20vw;
    margin: 15px 0 15px 0 !important;
`;

export { Container, Campo }