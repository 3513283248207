import htmlToPdfmake from "html-to-pdfmake"
import pdfMake from "pdfmake/build/pdfmake"
import DownloadVendaProps from "../../interfaces/downloadVendaProps"
import obterDescricaoFormaPagamento from "../../utils/enum/formaPagamentoDescricao"
import { toReal } from "../../utils/MaskMonetario"
import { useContext } from "react"
import { GFLContext } from "../../infra/GFLContext"
import { TypeAlertEnum } from "../../utils/enum/typeAlertEnum"

export const DownloadVenda = (props: DownloadVendaProps) => {

  const nomeCliente = props.cliente.nome ? props.cliente.nome.toUpperCase() : "";

  const layout = htmlToPdfmake(`
    <html>
    <head>
    </head>
    <body style="margin: 10px;">
    <div style="display: flex;flex-direction: column;width: 100%;height: 100%;background-color: #f5f5f5;border: #000000;border-width: 1px;border-style: solid;border-radius: 10px;margin: 10px;padding: 10px;">
    <h1 style="font-size: 30px;font-weight: bold;text-align: center;">Lima Surf Concept</h1>
    <h2 style="font-size: 20px;font-weight: bold;text-align: center;">Edifício Estrela do Mar - Av. Prefeito Cirino Adolfo Cabral, 8801 - Sala 02 - Gravatá, Navegantes - SC</h2>
    <h4 style="font-size: 15px;font-weight: bold;text-align: center;">(47) 3065-9933 - CNPJ: 34.057.343/0001-04</h4>
  </div>
  <div style="width: 100%;height: 100%;margin: 10px;display: flex;flex-direction: row;justify-content: space-evenly;">
    <text style="width: 100%;">Data da Venda: ${new Date(props.dataVenda).toLocaleDateString()}                                                                                                                                           </text>
    <text>Data da Emissão: ${new Date().toLocaleString()}</text>
  <text>                                                Forma de pagamento: ${obterDescricaoFormaPagamento(props.formaPagamento)}</text>
  </div>
  <div style="width: 100%;height: 100%;background-color: #f5f5f5;border: #000000;border-width: 1px;border-style: solid;border-radius: 10px;margin: 10px;padding: 10px;">
    <div>
      <div>
        <text>Nome: ${nomeCliente}</text>
      </div>
      <div style="display: flex;flex-direction: row;">
        <text style="margin-right: 50px;">CPF: ${props.cliente.cpf ? props.cliente.cpf : ""}                                                                            Fone: ${props.cliente.telefone ? props.cliente.telefone : ""}</text>
      </div>
    </div>
      <div>
      <table style="width: 100%; border: 1px solid black; border-collapse: collapse; margin-left: 110px; margin-top: 10px">
        <thead style="background-color: #adadad;">
          <tr>
            <th style="border: 1px solid black; border-collapse: collapse;">Código</th>
            <th style="border: 1px solid black; border-collapse: collapse;">Quantidade</th>
            <th style="border: 1px solid black; border-collapse: collapse;">Descrição</th>
            <th style="border: 1px solid black; border-collapse: collapse;">Valor unidade</th>
            <th style="border: 1px solid black; border-collapse: collapse;">Valor total</th>
          </tr>
        </thead>
        <tbody>
          ${props.vendaItens.map((item, index) => `
          <tr style="border-bottom: 1px solid black;">
            <td style="border: 1px solid black; border-collapse: collapse;">${index + 1}</td>
            <td style="border: 1px solid black; border-collapse: collapse;">${item.quantidade}</td>
            <td style="border: 1px solid black; border-collapse: collapse;">${item.produtoNome}</td>
            <td style="border: 1px solid black; border-collapse: collapse;">${toReal(item.produtoValor)}</td>
            <td style="border: 1px solid black; border-collapse: collapse;">${toReal(item.quantidade * item.produtoValor)}</td>
          </tr>
          `).join('')}
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">Desconto</td>
            <td>${toReal(props.desconto)}</td>
          </tr>
          <tr>
            <td colspan="4">Total</td>
            <td>${typeof props.valorTotal === 'string' ? props.valorTotal : toReal(props.valorTotal)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    </body>
    </html>
    `, {
    tableAutoSize: true,
  })

  const pdfDocGenerator = pdfMake.createPdf({
    content: layout,
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [30, 30, 30, 30],
  })

  try {
    pdfDocGenerator.open()
  } catch {
    pdfDocGenerator.getBase64((dataBase64) => {
      const linkSource = `data:application/pdf;base64,${dataBase64}`;
      const downloadLink = document.createElement("a");

      const fileName = `${nomeCliente}-${new Date(props.dataVenda).toLocaleDateString()}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      if (props.contexto) {
        const { contexto } = props
        contexto.setMessageAlert("Não foi possível abrir o arquivo em uma nova aba mas ele foi baixado em seu computador.")
        contexto.setTypeAlert(TypeAlertEnum.info)
        contexto.setOpenAlert(true)
      }
    });
  }
}