import React from "react"
import { IMaskInput } from "react-imask"

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const MaskCpf = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask="#00.000.000-00"
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref as any}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    )
  },
)

export default MaskCpf