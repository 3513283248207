import React, { useState, useEffect } from 'react'
import { IconButton, TextField, Divider, debounce } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { DataGrid, GridColDef, GridRowParams, ptBR } from '@mui/x-data-grid'
import Categoria from '../../../interfaces/categoria'
import axios from '../../../services/withAxios'
import { Header, Content, Image, StyledDataGrid } from './styles'
import CategoriaDialog from '../../components/CategoriaDialog'
import Clothes from '../../../assets/clothes.png'
import { EsconderPorNivelAcesso, getUser } from '../../../services/auth'
import { NivelAcessoEnum } from '../../../utils/enum/nivelAcessoEnum'

const colunas: GridColDef[] = [
  {
    field: 'nome',
    headerName: 'Nome',
    flex: 1
  },
]

const Categorias: React.FC = () => {
  const [pesquisa, setPesquisa] = useState<string>('')
  const [dialogAberto, setDialogAberto] = useState<boolean>(false)
  const [categorias, setCategorias] = useState<Categoria[]>([])
  const [categoriaSelecionado, setCategoriaSelecionado] = useState<Categoria | null>(null)
  const [pesquisarTrigger, setPesquisarTrigger] = useState<boolean>(false);

  const user = getUser();

  const pesquisar = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setPesquisa(event.target.value);
  }, 500)

  useEffect(() => {
    if (dialogAberto)
      return

    const buscarDados = async () => {
      if (pesquisa === '')
        await axios('/categoria')
          .then(resp => {
            setCategorias(resp.data === '' ? [] : resp.data)
          })
      else
        await axios(`/categoria/search/${pesquisa}`)
          .then(resp => {
            setCategorias(resp.data === '' ? [] : resp.data)
          })
    }

    buscarDados()
  }, [pesquisa, pesquisarTrigger, dialogAberto])

  const handleAdicionar = () => {
    setCategoriaSelecionado(null)
    setDialogAberto(!dialogAberto)
  }

  const handleClickLinha = (params: GridRowParams) => {
    setCategoriaSelecionado(params.row as Categoria)
    setDialogAberto(true)
  }

  return (
    <>
      <Image src={Clothes} />
      <Header>
        <TextField
          id="pesquisa"
          label="Pesquisar"
          onChange={pesquisar}
          type="search"
          fullWidth
          autoFocus
          autoComplete='off'
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <IconButton
          color="primary"
          onClick={_ => setPesquisarTrigger(!pesquisarTrigger)}
        >
          <SearchIcon />
        </IconButton>
        <EsconderPorNivelAcesso nivelAcesso={[NivelAcessoEnum.admin, NivelAcessoEnum.gerente]}>
          <>
            <Divider orientation="vertical" variant="middle" flexItem />
            <IconButton
              onClick={handleAdicionar}
              color="primary"
            >
              <AddCircleIcon />
            </IconButton>
          </>
        </EsconderPorNivelAcesso>
      </Header>
      <Content>
        <StyledDataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          columns={colunas}
          rows={categorias}
          disableColumnMenu={true}
          hideFooterSelectedRowCount={true}
          onRowClick={handleClickLinha}
          sx={{
            textTransform: 'uppercase'
          }}
        />
      </Content>
      {dialogAberto ? <CategoriaDialog aberto={dialogAberto} setAberto={setDialogAberto} entidade={categoriaSelecionado} /> : <></>}
    </>
  )
}

export default Categorias