import {
  Box,
  IconButton,
  Typography,
  Button,
  FormControl,
  Divider,
  Chip
} from "@mui/material"
import React, { useContext, useEffect } from "react"
import { useState } from "react"
import Item from "../../../interfaces/item"
import Produto from "../../../interfaces/produto"
import { Content, Campo, StyledDataGrid, BotaoFinalizarVenda, OpcoesGrid, DivisorResumo as DivisorFinalizacao } from "./styles"
import debounce from 'lodash.debounce'
import axios from '../../../services/withAxios';
import FinalizarCompraDialog from './../../components/FinalizarCompraDialog/index';
import { GridCallbackDetails, GridColDef, GridRowParams, GridValueFormatterParams, GridValueGetterParams, MuiEvent, ptBR } from "@mui/x-data-grid"
import CashRegister from '../../../assets/cash-register.png'
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCart from '../../../assets/shopping-cart.png'
import { GFLContext } from "../../../infra/GFLContext"
import { useConfirm } from "material-ui-confirm"
import { toReal } from "../../../utils/MaskMonetario"
import Vendedor from "../../../interfaces/vendedor"
import Cliente from "../../../interfaces/cliente"

const colunasProdutosFiltrados: GridColDef[] = [
  {
    field: 'nome',
    headerName: 'Nome',
    width: 250
  },
  {
    field: 'codigo',
    headerName: 'Código',
    flex: 1,
  },
  {
    field: 'valor',
    headerName: 'Valor',
    type: 'number',
    flex: 1,
    valueGetter: (produto: GridValueGetterParams<any, any>) => {
      if (!produto) return "R$ 0,00";

      return toReal(produto.value);
    }
  }
];

const FrenteDeCaixa: React.FC = () => {
  const [quantidade, setQuantidade] = useState<number | null>(1)
  const [produtoSelecionado, setProdutoSelecionado] = useState<Produto | undefined>()
  const [pesquisaInput, setPesquisaInput] = useState<string | null>("")
  const [dialogAberto, setDialogAberto] = useState<boolean>(false)
  const [todosProdutos, setTodosProdutos] = useState<Produto[]>([])
  const [produtosFiltrados, setProdutosFiltrados] = useState<Produto[]>([])
  const [clientes, setClientes] = useState<Cliente[]>([])
  const [vendedores, setVendedores] = useState<Vendedor[]>([])

  const contexto = useContext(GFLContext)
  const venda = contexto.venda

  const confirm = useConfirm()

  const colunas: GridColDef[] = [
    {
      field: 'produtoNome',
      headerName: 'Nome',
      width: 250
    },
    {
      field: 'quantidade',
      headerName: 'Quantidade',
      flex: 1
    },
    {
      field: 'produtoValor',
      headerName: 'Valor',
      flex: 1,
      valueGetter: (produto: GridValueGetterParams<any, any>) => {
        if (!produto) return "R$ 0,00";

        return toReal(produto.value);
      }
    },
    {
      field: 'remover',
      headerName: '',
      width: 50,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <IconButton color="error" onClick={() => removerItem(cellValues.row as Item)} component="span">
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ]

  useEffect(() => {
    const buscarDados = async () => {
      await axios('/produto')
        .then(resp => {
          setTodosProdutos(resp.data === '' ? [] : resp.data)
        })

        await axios.get('/cliente').then(({ data }) => {
          setClientes(data)
        })

        await axios.get('/vendedor').then(({ data }) => {
          setVendedores(data)
        })
    }

    buscarDados()
  }, [])

  useEffect(() => {
    filtrarProdutos(pesquisaInput || '')
  }, [pesquisaInput])

  useEffect(() => {
    adicionarItem()
  }, [produtoSelecionado])

  const filtrarProdutos = debounce(async (pesquisa: string) => {
    if (pesquisa === '') {
      setProdutosFiltrados([])
      return
    }

    const filtrados = todosProdutos.filter(p => p.codigo === pesquisa ||
      p.codigoDeBarra === pesquisa ||
      p.nome.toUpperCase().indexOf(pesquisa.toUpperCase()) > -1)

    if (filtrados.length > 0)
      setProdutosFiltrados(filtrados)
    else
      setProdutosFiltrados(todosProdutos)
  }, 500)

  const adicionarItem = () => {
    if (produtoSelecionado) {
      const item: Item = {
        produtoId: produtoSelecionado?.id ?? '',
        produtoNome: produtoSelecionado.nome,
        produtoValor: produtoSelecionado.valor,
        produtoCodigo: produtoSelecionado.codigo,
        quantidade: quantidade === 0 ? 1 : quantidade ?? 1
      }

      const itemExistente = venda.itens.find(itemExistente => itemExistente.produtoId === item.produtoId)

      if (itemExistente) {
        itemExistente.quantidade += item.quantidade
        venda.setItens([...venda.itens])
      } else {
        venda.setItens([...venda.itens, item])
      }

      setPesquisaInput('')
      setProdutoSelecionado(undefined)
      setQuantidade(1)
    }
  }

  const doubleClickLinha = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
    setProdutoSelecionado(params.row as Produto)
  }

  const removerItem = (item: Item) => {
    const novoItens = venda.itens.filter(i => i.produtoId !== item.produtoId)
    venda.setItens(novoItens)
  }

  const handleLimparVenda = () => {
    if (venda.itens.length === 0)
      return

    confirm({ description: 'Limpar a venda atual?', title: 'Tem certeza?', confirmationButtonProps: { autoFocus: true } })
      .then(() => venda.setItens([]))
      .catch(() => { });
  };

  const calcularTotal = () => {
    const total = venda.itens.reduce((total, item) => {
      return total + (item.produtoValor * item.quantidade)
    }, 0)

    if (total === 0)
      return toReal(0)

    return toReal(total)
  }

  const quantidadeTotalItens = () => {
    if (venda.itens.length > 0)
      return venda.itens.reduce((total, item) => {
        return total + item.quantidade
      }, 0)
    else
      return "0"
  }

  return (
    <>
      <img src={CashRegister} />
      <Content>
        <Box
          display="flex"
          flexDirection="column"
          padding="0vh 2vh 0vh 2vh"
          width="50%"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            width="100%"
            height="25vh"
          >
            <StyledDataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={produtosFiltrados}
              columns={colunasProdutosFiltrados}
              density="compact"
              //pageSize={5}
              onRowDoubleClick={doubleClickLinha}
              disableColumnMenu={true}
              hideFooter={true}
              sx={{
                textTransform: 'uppercase'
              }}
            />
          </Box>
          <FormControl fullWidth>
            <Campo
              label="Quantidade"
              id="quantidade"
              value={quantidade}
              onChange={(event) => setQuantidade(parseInt(event.target.value))}
              onKeyPress={(e: any) => e.key === 'Enter' ? setProdutoSelecionado(produtosFiltrados[0]) : null}
              type="number"
            />
          </FormControl>
          <FormControl>
            <Campo
              label="Código/Barras/Nome..."
              id="pesquisa"
              value={pesquisaInput}
              inputProps={{ style: { textTransform: "uppercase" } }}
              onChange={(event) => { setPesquisaInput(event.target.value) }}
              type="search"
              onKeyPress={(e: any) => e.key === 'Enter' ? setProdutoSelecionado(produtosFiltrados[0]) : null}
              autoComplete="off"
              autoFocus={true}
            />
          </FormControl>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          width="50%"
        >
          <StyledDataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            columns={colunas}
            rows={venda.itens}
            density="compact"
            getRowId={(item) => Math.random()}
            disableColumnMenu={true}
            hideFooterSelectedRowCount={true}
            hideFooter={true}
            sx={{
              textTransform: 'uppercase',
              width: '100%',
              height: '80%'
            }}
          />
          <OpcoesGrid>
            <Typography>{`${quantidadeTotalItens()} Itens`}</Typography>
            <IconButton color="error" component="span" onClick={handleLimparVenda}>
              <img src={ShoppingCart} />
            </IconButton>
          </OpcoesGrid>
        </Box>
        <DivisorFinalizacao>
          <Chip label="FINALIZAÇÃO" />
        </DivisorFinalizacao>
        <Box
          display="flex"
          justifyContent="space-between"
          align-items="center"
          width="100%"
          marginTop="5vh"
          marginBottom="2vh"
        >
          <div></div>
          <BotaoFinalizarVenda variant="contained" color="primary" disabled={venda.itens.length === 0} onClick={() => setDialogAberto(true)}>
            <Typography>Finalizar Venda</Typography>
          </BotaoFinalizarVenda>
          <Typography
            width="20vw"
            variant="h4"
            color="initial"
          >Total: {calcularTotal()}</Typography>
        </Box>
      </Content>
      {dialogAberto ? <FinalizarCompraDialog 
                        aberto={dialogAberto} 
                        setAberto={setDialogAberto}
                        clientes={clientes}
                        vendedores={vendedores} 
                      /> : <></>}
    </>
  )
}

export default FrenteDeCaixa