import {
    Box,
    TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Content = styled(Box)`
    display: flex;
    padding: 1%;
    flex-wrap: wrap;
`;

const Campo = styled(TextField)`
    margin: 1vh 0 1vh 0;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
`;

export { Content, Campo };