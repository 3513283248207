import React, { useState } from 'react'
import {
    MainContainer,
    LoginContainer,
    LoginField,
    LoginTitle,
    Logo,
    IntroContainer,
} from './styles'
import axios from '../../../services/withAxios';
import Button from '@mui/material/Button'
import { useNavigate } from "react-router-dom"
import { login as Logar } from '../../../services/auth'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircle';
import VpnKey from '@mui/icons-material/VpnKey';
import LimaLogo from '../../../assets/lima-simbol-black.png';
import LimaFullLogo from '../../../assets/lima-full-simbol.png';

const Login: React.FC = () => {
    const [login, setLogin] = useState<string>('')
    const [senha, setSenha] = useState<string>('')

    const navigate = useNavigate()

    const handleEntrar = (e: React.FormEvent) => {
        e.preventDefault()

        axios.post('/auth', {
            login: login.toUpperCase(),
            senha
        }).then((response) => {
            if (response.status === 200 && response.data !== "") {
                Logar(response.data)
                navigate("/produtos")
            }
        })
    }

    return (
        <>
            <MainContainer>
                <IntroContainer>
                    <Logo>
                        <img src={LimaFullLogo}></img>
                    </Logo>
                </IntroContainer>
                <LoginContainer onSubmit={handleEntrar}>
                    <div>
                        <img width={100} height={87} src={LimaLogo}></img>
                    </div>
                    <LoginTitle>
                        Entre com suas credenciais
                    </LoginTitle>
                    <LoginField
                        className="login-field"
                        placeholder="Login"
                        autoFocus={true}
                        onChange={event => setLogin(event.target.value)}
                        value={login}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        required
                    />
                    <LoginField
                        type="password"
                        className="login-field"
                        placeholder="Senha"
                        onChange={event => setSenha(event.target.value)}
                        value={senha}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <VpnKey />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        required
                        autoComplete='off'
                    />
                    <Button color="primary" variant='contained' type="submit">Continuar</Button>
                </LoginContainer>
            </MainContainer>
        </>
    )
}

export default Login