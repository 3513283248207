import React, { lazy, Suspense, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Divider,
  Typography,
} from '@mui/material'

import { Content, DivisorStepper } from "./styles";

const SelecionarNFeDialog = lazy(() => import("../SelecionarNFeDialog"));
const RevisarNfeDialog = lazy(() => import("../RevisarNfeDialog"));

const ImportarNFeDialog: React.FC<{ aberto: boolean, setAberto: React.Dispatch<React.SetStateAction<boolean>> }> = ({ aberto, setAberto }) => {
  const [activeStep, setActiveStep] = useState(0)
  const steps = ["Selecionar XML", "Revisar Itens"];

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }, [setActiveStep])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  };

  const getStepContent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <SelecionarNFeDialog advanceStep={handleNext} setDialogAberto={setAberto} />
          </Suspense>
        )
      case 1:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <RevisarNfeDialog setDialogAberto={setAberto} />
          </Suspense>
        )
      default:
        return null
    }
  }, [activeStep, handleNext, setAberto])

  const getStepTitle = useCallback(() => {
    switch (activeStep) {
      case 0:
        return "Selecione a nota fiscal"
      case 1:
        return "Revise a nota fiscal"
      default:
        return ""
    }
  }, [activeStep])

  const handleSuccessStep = (index: number) => {
    if (index === 0 && activeStep === 1) {
      return (
        <Typography variant="caption" color="green">
          Sucesso!
        </Typography>
      )
    }
  }

  return (
    <Dialog open={aberto}
      maxWidth={activeStep === 0 ? 'sm' : 'xl'}
      onClose={() => setAberto(false)}>
      <DialogTitle id="importarNfe-dialog">
        {"Importar NF-e"}
      </DialogTitle>
      <DialogContent>
        <DivisorStepper />
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel optional={handleSuccessStep(index)}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <DivisorStepper />
        {getStepContent()}
      </DialogContent>
    </Dialog>
  );
}

export default ImportarNFeDialog