import { useState } from "react"
import { DialogActions, Button, Dialog, DialogTitle, Typography, Divider } from "@mui/material"
import { Content, Card, Divisor } from "./style"
import { useConfirm } from 'material-ui-confirm';
import { DesabilitarCampoCrud, getUser } from "../../../services/auth";
import Totalizador from "../../../interfaces/totalizador";

interface TotalizadorProps {
    dados: Totalizador[]
    total: number
}

const TotalizadorComponent: React.FC<TotalizadorProps> = ({ dados, total }) => {
    const formatarValorMonetario = (valor: number) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(valor)
    }

    return (
        <Content>
            {dados.map(item => (
                <Card key={item.nome}>
                    <Typography variant="subtitle1" fontSize={"1vw"} color="initial">{item.nome}</Typography>
                    <Divisor light={true} />
                    <Typography variant="body1" fontSize={"0.8vw"} color="initial">Total: {formatarValorMonetario(item.total)}</Typography>
                    <Typography variant="body1" fontSize={"0.8vw"} color="initial">Comissão: {formatarValorMonetario(item.segundoValor)}</Typography>
                </Card>
            ))}
            <Card key="totalizador" sx={{ marginLeft: "auto", backgroundColor: "#F0F0F0" }}>
                    <Typography variant="subtitle1" fontSize={"1.3vw"} color="initial">Total</Typography>
                    <Divisor light={true} />
                    <Typography variant="subtitle1" fontSize={"1vw"} color="initial">{formatarValorMonetario(total)}</Typography>
                </Card>
        </Content>
    )
}
export default TotalizadorComponent