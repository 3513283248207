import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { Modal, Box, Typography, TextField, Chip, Button, FormControl, InputLabel, MenuItem, Divider } from '@mui/material'
import {
    Content,
    Header,
    Campo,
    Filters,
    Row,
    ButtonsSection,
    ActionButton,
    ReportInfo,
    DivisorGrid,
    TitleIcon,
    NoDataInfo,
    VendedorFormControl,
    VendedorCampo,
    StyledDataGrid
} from './styles'
import SearchIcon from '@mui/icons-material/Search'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import CloseIcon from '@mui/icons-material/Close';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import brLocale from 'date-fns/locale/pt-BR'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { toReal } from '../../../utils/MaskMonetario'
import axios from '../../../services/withAxios';
import Vendedor from '../../../interfaces/vendedor'
import { DataGrid, GridColDef, GridRowParams, GridValidRowModel, GridValueGetterParams, ptBR } from '@mui/x-data-grid'
import TotalizadorComponent from '../TotalizadorComponent'
import Totalizador from '../../../interfaces/totalizador'

interface Detalhe {
    vendaId: string;
    dataVenda: string;
    valorTotalVenda: number;
    valorDescontoVenda: number;
    valorUnitarioItem: number;
    quantidadeItem: number;
    codigoProduto: string;
    codigoBarraProduto: string;
    produtoNome: string;
    vendedorNome: string;
    vendedorId: string;
    corLinha: boolean;
}

interface VendedorRelatorio {
    vendedorNome: string;
    vendedorTotal: number;
    valorComissao: number;
}

interface NovoRelatorio {
    detalhes: Detalhe[];
    vendedores: VendedorRelatorio[];
    total: number;
}

// const data: NovoRelatorio = {
//     detalhes: [
//         {
//             vendaId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//             dataVenda: "2024-02-18T21:53:08.012Z",
//             valorTotalVenda: 0,
//             valorDescontoVenda: 0,
//             valorUnitarioItem: 0,
//             quantidadeItem: 0,
//             codigoProduto: "string",
//             codigoBarraProduto: "string",
//             produtoNome: "string",
//             vendedorNome: "string",
//             vendedorId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//             corLinha: false
//         },
//     ],
//     vendedores: [
//         {
//             vendedorNome: "string",
//             vendedorTotal: 0,
//             valorComissao: 0,
//         },
//     ],
//     total: 0,
// };

const colunas: GridColDef[] = [
    {
        field: 'dataVenda',
        headerName: 'Data',
        headerClassName: "report-grid-header",
        width: 150,
        valueGetter: (data: GridValueGetterParams<Date, any>) => {
            if (data.value === "")
                return "";

            return new Date(data.value).toLocaleString().slice(0, -10);
        },
        sortable: false
    },
    {
        field: 'produtoNome',
        headerName: 'Produto',
        width: 300,
        sortable: false
    },
    {
        field: 'vendedorNome',
        headerName: 'Vendedor',
        valueFormatter: (params: any) => {
            return params.value !== null ? params.value : "-"
        },
        flex: 1,
        sortable: false
        //width: 40
    },
    {
        field: 'valorUnitarioItem',
        headerName: 'Vlr. Unitário',
        valueFormatter: (params: any) => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(params.value)
        },
        flex: 1,
        sortable: false
        // width: 60
    },
    {
        field: 'quantidadeItem',
        headerName: 'Qntd.',
        width: 70,
        sortable: false
    },
    {
        field: 'valorDescontoVenda',
        headerName: 'Desconto',
        valueFormatter: (params: any) => {
            if (params.value === 0)
                return "";

            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(params.value)
        },
        // width: 60
        flex: 1,
        sortable: false
    },
    {
        field: 'valorTotalVenda',
        headerName: 'Total',
        valueFormatter: (params: any) => {
            if (params.value === 0)
            return "";

            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(params.value)
        },
        // width: 60
        flex: 1,
        sortable: false
    }
];

const ReportDialog: React.FC<{ open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> }> = ({ open, setOpen }) => {
    const [dados, setDados] = useState<NovoRelatorio | null>()
    const [dataInicial, setDataInicial] = useState<Date | null>(new Date())
    const [dataFinal, setDataFinal] = useState<Date | null>(new Date())
    const [resultado, setResultado] = useState<number | null>(null)
    const [comissao, setComissao] = useState<number | null>(null)
    const [pesquisarTrigger, setPesquisarTrigger] = useState<boolean>(false)
    const [vendedores, setVendedores] = useState<Vendedor[]>([])
    const [vendedorSelecionado, setVendedorSelecionado] = useState<string>('0')

    const firstUpdate = useRef(true);

    let totalizadorDados: Totalizador[] = []

    if (dados && dados.vendedores)
        totalizadorDados = dados.vendedores.map((vendedor: VendedorRelatorio) => ({
            nome: vendedor.vendedorNome ?? "-",
            total: vendedor.vendedorTotal,
            segundoValor: vendedor.valorComissao
        } as Totalizador));


    useEffect(() => {

        console.log('useeffect');
        if (firstUpdate.current) {
            firstUpdate.current = false
            return
        }

        const buscarDados = async () => {
            const filtros = {
                dataInicio: dataInicial,
                dataFim: dataFinal
            };

            const filtrosComVendedor = {
                dataInicio: dataInicial,
                dataFim: dataFinal,
                vendedorId: vendedorSelecionado,
            }

            const { data } = await axios.post('/relatorio/vendasdetalhe',
                vendedorSelecionado === '0' ? filtros : filtrosComVendedor, { params: { avoidFeedbackMessage: true } })

            if (data.detalhes) {
                var vendaIdAtual = "";
                var corLinhaAtual = true;
                const updatedList = data.detalhes.map((item: Detalhe, index: number, array: any) => {
                    if (item.vendaId != vendaIdAtual) {
                        vendaIdAtual = item.vendaId;
                        item.corLinha = !corLinhaAtual;
                        corLinhaAtual = !corLinhaAtual;
                    } else {
                        item.corLinha = corLinhaAtual;
                        item.dataVenda = "";
                        item.valorTotalVenda = 0;
                        item.valorDescontoVenda = 0;
                    }

                    // Check if the current item has the same vendaId as the previous one
                    //const isSameGroup = index > 0 && item.vendaId === array[index - 1].vendaId;

                    // Set corLinha based on the group
                    //item.corLinha = isSameGroup ? "Cinza" : "Azul";

                    return item;
                });

                data.detalhes = updatedList
            }
            
            setDados(data)
            // if (data.valorTotal === 0)
            //     setResultado(null)
            // else
            //     setResultado(data.valorTotal)
        }

        buscarDados()
    }, [pesquisarTrigger])

    useEffect(() => {

        const buscarVendedores = async () => {
            axios.get('/vendedor', { params: { avoidLoadingScreen: true } }).then(({ data }) => {
                setVendedores(data)
            })
        }

        if (!open) {
            handleLimparFiltros()
            setResultado(null)
        } else {
            buscarVendedores()
        }
    }, [open]);

    const handlePesquisar = () => {
        setPesquisarTrigger(!pesquisarTrigger)
    }

    useEffect(() => {
        setResultado(null)
        setComissao(null)
    }, [vendedorSelecionado])

    const handleLimparFiltros = () => {
        setDataInicial(new Date())
        setDataFinal(new Date())
        setResultado(null)
        setComissao(null)
    }

    const handleFechar = () => {
        setOpen(false)
    }

    const comissaoValida = comissao && comissao !== 0

    const renderizarComissaoVendedor = () => {
        if (comissaoValida && vendedorSelecionado !== '0')
            return (
                <>
                    <Divider />
                    <Typography variant="h4" color="#8d6e63">Comissão Vendedor: {toReal(comissao)}</Typography>
                </>
            )

        return null;
    }


    return (
        <Modal
            onClose={() => setOpen(false)}
            open={open}>
            <Content>
                <Header>
                    <TitleIcon />
                    <Typography variant="h1" fontWeight={'bold'} fontSize={'1.4rem'} marginBottom={"9px"} color="initial">Calcular saldo de vendas</Typography>
                </Header>
                <Filters>
                    <Row>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
                            <DatePicker
                                label="Data Inicial"
                                value={dataInicial}
                                inputFormat="dd/MM/yyyy"
                                onChange={(novaData) => {
                                    setDataInicial(novaData);
                                }}
                                renderInput={(params) => <TextField style={{ margin: "1vh 0 1vh 0" }} size='small' {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
                            <DatePicker
                                label="Data Final"
                                value={dataFinal}
                                inputFormat="dd/MM/yyyy"
                                onChange={(novaData) => {
                                    setDataFinal(novaData);
                                }}
                                renderInput={(params) => <TextField style={{ margin: "1vh 0 1vh 0" }} size='small' {...params} />}
                            />
                        </LocalizationProvider>
                        <VendedorFormControl>
                            <InputLabel id="vendedorId">Vendedor</InputLabel>
                            <VendedorCampo
                                id="vendedorId"
                                size='small'
                                value={vendedorSelecionado}
                                onChange={(e) => setVendedorSelecionado(e.target.value as string)}
                            >
                                <MenuItem key={'0'} value={'0'}>TODOS</MenuItem>
                                {
                                    vendedores.map(vendedor => (
                                        <MenuItem key={vendedor.id} value={vendedor.id}>{vendedor.nome.toUpperCase()}</MenuItem>
                                    ))
                                }
                            </VendedorCampo>
                        </VendedorFormControl>
                    </Row>
                </Filters>
                <ButtonsSection>
                    <ActionButton variant="contained" size="small" color="primary" startIcon={<SearchIcon />} onClick={handlePesquisar}>
                        Pesquisar
                    </ActionButton>
                    <ActionButton variant="outlined" size="small" color="primary" startIcon={<FilterAltOffIcon />} onClick={handleLimparFiltros}>
                        Limpar Filtros
                    </ActionButton>
                    <ActionButton variant="outlined" size="small" color="primary" startIcon={<CloseIcon />} onClick={handleFechar}>
                        Fechar
                    </ActionButton>
                </ButtonsSection>
                <DivisorGrid>
                    <Chip icon={<QueryStatsIcon />} label="Resultado" />
                </DivisorGrid>
                <ReportInfo>
                    {(dados?.vendedores && dados.vendedores.length > 0) && (
                        <TotalizadorComponent dados={totalizadorDados} total={dados.total}/>
                    )}
                    {(dados?.detalhes && dados.detalhes.length > 0) && (
                        <StyledDataGrid
                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                            columns={colunas}
                            rowHeight={25}
                            getRowClassName={(params) => "report-grid-theme--" + (params.row.corLinha ? "Cinza" : "Branco")}
                            getRowId={(row) => row.vendaId + "|" + row.produtoNome}
                            rows={dados.detalhes}
                            disableColumnMenu={true}
                            disableColumnFilter={true}
                            disableDensitySelector={true}
                            disableColumnSelector={true}
                            hideFooterSelectedRowCount={true}
                            sx={{
                                textTransform: 'uppercase',
                                "& td": { border: 0 }
                            }}
                        />
                    )}
                    {/* {resultado && <Typography variant="h3" color="initial">Total: {toReal(resultado)}</Typography>} */}
                    {(!dados || (dados?.detalhes && dados.detalhes.length < 1)) && (
                        <NoDataInfo>
                            <DoDisturbIcon /> Sem informações para mostrar...
                        </NoDataInfo>
                    )}
                    {renderizarComissaoVendedor()}
                </ReportInfo>
            </Content>
        </Modal>
    )
}

export default ReportDialog;